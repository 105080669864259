<template>
  <div class="row flex-column flex-md-row h-100 justify-content-between">
    <div class="col col-md-12 mb-12 mb-md-0">
      <div class="row flex-column justify-content-around">
        <h3>Text Generation Statistics</h3>

        <div class="col-lg-4 mt-2 ml-auto">
          <label>{{ $t('Date', {value: 'Date'}) }}</label>
          <div class="input-daterange input-group" id="m_datepicker">
            <input type="text" autocomplete="off" class="form-control m-input"
                   :placeholder="$t('After', {value: 'After'})"
                   name="fromDate"
                   id="fromDate"
                   ref="fromDate"
                   data-type="date"
                   data-date-to-date="#toDate"
                   data-search-key="fromDate"
                   data-search-type="="
            >
            <div class="input-group-append">
                <span class="input-group-text">
                  <i class="la la-ellipsis-h"></i>
                </span>
            </div>
            <input type="text" autocomplete="off" class="form-control m-input"
                   :placeholder="$t('Before', {value: 'Before'})"
                   name="toDate"
                   id="toDate"
                   ref="toDate"
                   data-type="date"
                   data-date-from-date="#fromDate"
                   data-search-key="toDate"
                   data-search-type="="
            >
          </div>
        </div>
        <div class="col-md-12 mb-3">
          <canvas id="myChart" style="width:100%;max-width:800px"></canvas>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {coreJs} from "utilPath/coreJs";
import {_elementUtil} from "../../../tools/src/core/util/element-util";

export default {
  data() {
    return {
      name: "Text Generation Statistics",
      title: "Text Generation Statistics",
    }
  },
  mounted() {
    coreJs.init({name: this.name, title: this.title});
    this.mountGraph()
  },
  methods: {
    mountGraph() {
      const arrCopy = JSON.parse(localStorage.getItem("arrCopy"));
      if (!arrCopy) {
        return;
      }

      const xValues = arrCopy.map(item => {
        return `${item.date} | Created: ${item.lengthClick} | Share: ${item.lengthCopy} |`;
      });
      const yValues = arrCopy.map(item => {
        return item.lengthClick + item.lengthCopy;
      });
      const barColors = [];
      for (let i = 0; i < arrCopy.length; i++) {
        const randomColor = Math.floor(Math.random() * 16777215).toString(16);
        barColors.push(`#${randomColor}`);
      }

      new Chart("myChart", {
        type: "bar",
        data: {
          labels: xValues,
          datasets: [{
            backgroundColor: barColors,
            data: yValues
          }]
        },
        options: {
          title: {
            display: true,
            text: "Statistics"
          }
        }
      });
    }
  },
}

</script>

<style>

</style>
